import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import  moment from 'moment'
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent  implements OnInit {

  public weekDays:string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  public months:string[] = ['Jan', 'Feb', 'Mar', 'Apr','May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  public date = new Date();
  public daysArray:any;
  public disableDate:boolean= false;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  public yearArr:any[] = [];
  public showYear = false;
  public showDays = false;
  public showMonths = false;
  public selectedYear:any = '';
  public selectedDay:any = '';
  public selectedMonth:any = '';
  public selectedMonth2:any = '';
  index = 12;
  @Input() data: any;
  @Input() data2: any;
  @Input() mobileLeft: boolean = false;
  @Output() dateValue = new EventEmitter<any>();
  @Input() inputHeight = "auto";
  showDatePicker = false;
  public selector:boolean = false;

  constructor() { }


  ngOnInit() {
    
    this.displayYears()
  }

  //revisit change of days when user changes the selected  year
  countDaysInMonth(year:any){
    let daysArray = []
    let date = `${this.selectedMonth}-${year}`
    let days = this.numberOfDaysInMonth(year);
    let firstDay = moment(date, 'MMM-YYYY').startOf('M');
    for(let i = 1; i<=days; i++ ){
      daysArray.push(i)
    }
     for(let n=0; n<firstDay.weekday(); n++){
       daysArray.unshift(null)
     }
    return daysArray;

  }
  numberOfDaysInMonth(year:any) {
    let index = this.months.indexOf(this.selectedMonth)
    return new Date(year, index + 1, 0).getDate();
  }

  currentDayCheck(day:any){
    if(!day){
      return false
    }
    return moment().format('L')===day.format('L');
  }

  displayYears(){
    let year = this.currentYear;
    for (let i = 0; i < 12; i++) {
      this.yearArr.unshift(year--);
    }
    return this.yearArr;
  }
  changeYear(value:any){
    let years = [];
    if(value=='add'){
      let year = this.yearArr[11];
      this.yearArr = []
      for (let i =0; i < 12; i++) {
        years.push(year++);
        
      }
      years.sort()
    }else{
      let year = this.yearArr[0];
      {for (let i = 12; i >0; i--) {
        year = year-1
        years.push(year);
      }}
    }
    years.sort()
    return this.yearArr = years;
  }

  selectYear(year:any){
    this.selectedYear = year;
    this.showMonths = true;
    this.showYear = false;
    return this.selectedYear;
  }
  selectMonth(month:any, index:any){
    this.selectedMonth = month;
    this.selectedMonth2 = index;
    this.showMonths = false;
    this.showDays = true;
    this.daysArray = this.countDaysInMonth(this.selectedYear);
    return this.selectedMonth
  }
  selectDay(day:any){
    this.selectedDay = day;
    this.showDays = false;
    if(!this.selectedYear){
      this.selectedYear = this.currentYear;
    }
    this.data = `${this.selectedMonth}/${this.selectedDay}/${this.selectedYear}`;
    this.data2 = `${this.selectedYear}-${(this.selectedMonth2 < 10)? '0'+this.selectedMonth2 :this.selectedMonth2}-${(this.selectedDay < 10)? '0'+this.selectedDay :this.selectedDay}`;
    this.dateValue.emit([this.data2, this.data]);
  }
  changeMonth(value:string){
    if(value =='add'){
      this.selectedYear+=1;
      this.countDaysInMonth(this.selectedYear);
      return this.selectedYear;
    }else{
      this.selectedYear-=1;
      this.countDaysInMonth(this.selectedYear);
      return this.selectedYear;
    }
  }

  showMonth(){
    this.showDays = false;
    this.showMonths = true;
    this.showYear = false;
  }
  showYears(){
    this.showDays = false;
    this.showMonths = false;
    this.showYear = true;
  }
  closeSelect() {
    this.showDatePicker = false;
    this.showMonths = false;
    this.showYear = false;
    this.showDays = false;
  }
  toggleDropdown() {
    this.selector = !this.selector;
    this.showYear = false;
    this.showDays = false;
    this.showMonths = true;
  }
}
