    <div class="body-container" [tabIndex]="index" (blur)="closeSelect()" >
      <div class="display-field" [style.height.px]="inputHeight" (click)="toggleDropdown()" >
        <div class="input-value" [ngClass]="selector? 'active-input-value': 'input-value'" >
          {{data ? data : 'mm/dd/yyyy'}}</div>
        <img src="../../assets/icons/date-picker.svg"  alt="">
      </div>
       @if (showYear || showDays || showMonths) {
        <div class="calendar-container" [ngClass]="((mobileLeft) && (showDays)) ? 'calendar-container-mobile':''">
          <div class="inner-container" >
            @if (showYear) {
              <div class="year-list-containter" >
                <div class="year-header">
                  <img src="../../assets/icons/u_angle-left-b.svg" class="arr" alt="" (click)="changeYear('minus')">
                  {{yearArr[0]}} - {{yearArr[11]}}
                  <img src="../../assets/icons/u_angle-right-b.svg" alt="" class="arr" [ngClass]="yearArr[11]>=currentYear ? 'disabled-click' : ''" (click)="changeYear('add')">
                </div>
                <div class="year-list">
                  @for (year of yearArr; track $index) {
                    <div class="year" (click)="selectYear(year)">
                        {{year}}
                      </div>
                  }
                </div> 
              </div>
            }
            @if (showMonths) {
                <div class="month-list-containter" >
                  <div class="month-header">
                    @if (!selectedYear) {
                      <img src="../../assets/icons/u_angle-left-b.svg" class="arr" alt="" [ngClass]="!selectedYear ? 'disable-click': ''" >
                    }
                    @if (selectedYear) {
                      <img src="../../assets/icons/u_angle-left-b.svg" class="arr" alt=""  (click)="changeMonth('minus')">
                    }
                    
                    <div (click)="showYears()" class="year-txt">{{selectedYear || currentYear}}</div>
                    @if (!selectedYear) {
                      <img src="../../assets/icons/u_angle-right-b.svg" class="arr" alt=""  [ngClass]="!selectedYear ? 'disable-click': ''" >
                    } 
                    @if (selectedYear) {
                      <img src="../../assets/icons/u_angle-right-b.svg" 
                        alt="" class="arr"  
                        [ngClass]="selectedYear>=currentYear ? 'disbled-click': ''" 
                        (click)="changeMonth('add')"
                      >
                    }
                  </div>
                  <div class="month-list">
                    <div class="months"
                      *ngFor="let month of months; let i = index;"
                      (click)="selectMonth(month, i+1)"
                    >
                    {{month}}
                    </div>
                  </div>
                </div>
            }
            
            @if (showDays) {
                <div class="showdays" >
                  <div class="month-year">
                    <img src="../../assets/icons/u_angle-left-b.svg" class="arr" alt="" >
                    <div class="month" (click)="showMonth()">{{selectedMonth}} {{selectedYear}}</div>
                    <img src="../../assets/icons/u_angle-right-b.svg" alt="" class="arr" [ngClass]="selectedYear>=currentYear ? 'disbled-click' : ''" (click)="changeMonth('add')">              
                  </div>
                  
                  
                  <div class="days-title" >
                    @for (day of weekDays; track $index) {
                      <div class="day" >{{day}}</div>
                    }
                  </div>
                  <div class="date-container">
                    @for (day of daysArray; track $index) {
                      <div class="days-counter" 
                        [ngClass]="!day ? 'inactive-day' : ''" 
                        (click)="selectDay(day)"
                      >
                        {{day}}
                      </div>
                    }
                  </div>
                </div>
    
            }
                      
        
          </div>
        </div>
       }
        
        
    </div>