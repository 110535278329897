import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CalendarComponent } from '../calendar/calendar.component';
import { SelectFieldComponent } from '../select-field/select-field.component';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-input-fields',
  standalone: true,
  imports: [
    FormsModule,
    SelectFieldComponent,
    ReactiveFormsModule,
    CalendarComponent,
    CommonModule,
  ],
  templateUrl: './input-fields.component.html',
  styleUrls: ['./input-fields.component.scss'],
})
export class InputFieldsComponent implements OnInit {
  @Input() height?: any = '';
  @Input() placeholder: string = '';
  @Input() type?:
    | `text`
    | 'password'
    | 'email'
    | 'number'
    | 'select'
    | `textarea`
    | 'date'
    | 'search';
  @Input() width: any = '';
  @Input() row?: number;
  @Input() cols?: number;
  @Input() margin?: any;
  @Input() padding?: any;
  @Input() formValue: any;
  @Input() dropDownList: any;
  public showDropDown = false;
  public selectedItem: any;
  public showPassword: boolean = false;
  @Input() passwordToggler: any = false;
  @Output() fieldData = new EventEmitter<any>();
  @Output() selectData = new EventEmitter<any>();
  //@Input()  ImgSrc ="../../../assets/icons/eye-slash.svg"
  @Input('control') control: FormControl = new FormControl();
  // @Input("errorMessageName") errorMessageName = "input";
  validate: Validators = Validators.required;

  /**
   * Custom error messages for input element
   *
   */
  errorMessages: Record<string, string> = {
    required: 'This field is required',
    email: 'Invalid email address',
    pattern: 'This input is not valid',
    requiredLength: 'Password must be at least 8 characters long',
  };
  /**
   * Used to get input validation message for the input's instance
   * @returns {boolean}
   */
  getValidator(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  constructor() {}

  ngOnInit() {}

  togglePswd() {
    this.showPassword = !this.showPassword;
    if (this.showPassword == true) {
      this.type = 'text';
      this.passwordToggler = true;
    } else {
      this.type = 'password';
    }
  }
  setItem(name: any) {
    this.selectedItem = name;
    this.selectData.emit(this.selectedItem);
    this.showDropDown = !this.showDropDown;
  }
  dateInput(value: any) {
    console.log(value, 'value');
  }

  outPutData() {
    this.fieldData.emit(this.formValue);
  }

  clearSearch() {
    if (this.type == 'search' && this.formValue) {
      this.formValue = '';
      this.fieldData.emit(this.formValue);
    }
  }
}
